import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useColorModes } from "@coreui/react";

import "./style/css/all.css";
import "./style/css/sharp-light.css";
import "./style/css/sharp-regular.css";
import "./style/css/sharp-solid.css";
import "./scss/style.scss";
import "./index.css";
import "./App.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { LoaderProvider } from "./context/LoaderContext";

const Missing = lazy(() => import("./components/Missing"));
const RequireAuth = lazy(() => import("./components/RequireAuth"));
const Unauthorized = lazy(() => import("./components/Unauthorized"));

const Layout = lazy(() => import("./components/Layout"));

const Login = lazy(() => import("./pages/login"));
const ForgotPassword = lazy(() => import("./pages/forgotpassword"));
const ResetPassword = lazy(() => import("./pages/resetpassword"));

const Dashboard = lazy(() => import("./pages/dashboard"));

//Services
const Services = lazy(() => import("./pages/services/services"));
const AddService = lazy(() => import("./pages/services/addservice"));
const UpdateService = lazy(() => import("./pages/services/updateservice"));
const ViewService = lazy(() => import("./pages/services/viewservice"));

//Industries
const Industries = lazy(() => import("./pages/industries/industries"));
const AddIndustry = lazy(() => import("./pages/industries/addindustry"));
const UpdateIndustry = lazy(() => import("./pages/industries/updateindustry"));
const ViewIndustry = lazy(() => import("./pages/industries/viewindustry"));

//About-us
const AboutUs = lazy(() => import("./pages/aboutus/aboutus"));

//Career
const Career = lazy(() => import("./pages/career/career"));

//Jobs
const Jobs = lazy(() => import("./pages/jobs/jobs"));
const AddJob = lazy(() => import("./pages/jobs/addjob"));
const UpdateJob = lazy(() => import("./pages/jobs/updatejob"));
const ViewJob = lazy(() => import("./pages/jobs/viewjob"));

//Blogs
const Blogs = lazy(() => import("./pages/blogs/blogs"));
const AddBlog = lazy(() => import("./pages/blogs/addblog"));
const UpdateBlog = lazy(() => import("./pages/blogs/updateblog"));
const ViewBlog = lazy(() => import("./pages/blogs/viewblog"));
const ManageCategory = lazy(() => import("./pages/blogs/managecategory"));

//CaseStudy
const CaseStudys = lazy(() => import("./pages/casestudy/casestudys"));
const AddCaseStudy = lazy(() => import("./pages/casestudy/addcasestudy"));
const UpdateCaseStudy = lazy(() => import("./pages/casestudy/updatecasestudy"));
const ViewCaseStudy = lazy(() => import("./pages/casestudy/viewcasestudy"));

//TechnologyImage
const TechnologyImage = lazy(() =>
  import("./pages/technologyimage/technologyimage")
);

//LandingPage
const LandingPage = lazy(() => import("./pages/landingpage/landingpage"));

//SeoPage
const Seo = lazy(() => import("./pages/seo/seo"));

//User
const Users = lazy(() => import("./pages/users/users"));
const AddUser = lazy(() => import("./pages/users/adduser"));
const UpdateUser = lazy(() => import("./pages/users/updateuser"));
const ViewUser = lazy(() => import("./pages/users/viewuser"));

function App() {
  const { isColorModeSet, setColorMode } = useColorModes(
    "coreui-pro-react-admin-template-theme-bright"
  );
  const storedTheme = useSelector((state) => state.theme);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    const theme =
      urlParams.get("theme") &&
      urlParams.get("theme").match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
    // eslint-disable-next-line
  }, []);

  return (
    <LoaderProvider>
      <Suspense>
        <Routes>
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/resetpassword/:resetPasswordKey"
            element={<ResetPassword />}
          />
          <Route element={<RequireAuth />}>
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/landingpage" element={<LandingPage />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/career" element={<Career />} />
              <Route path="/technologyimage" element={<TechnologyImage />} />
              <Route path="/seo" element={<Seo />} />

              <Route path="/services" element={<Services />} />
              <Route path="/addservice" element={<AddService />} />
              <Route path="/updateservice/:id" element={<UpdateService />} />
              <Route path="/viewservice/:id" element={<ViewService />} />

              <Route path="/industries" element={<Industries />} />
              <Route path="/addindustry" element={<AddIndustry />} />
              <Route path="/updateindustry/:id" element={<UpdateIndustry />} />
              <Route path="/viewindustry/:id" element={<ViewIndustry />} />

              <Route path="/jobs" element={<Jobs />} />
              <Route path="/addjob" element={<AddJob />} />
              <Route path="/updatejob/:id" element={<UpdateJob />} />
              <Route path="/viewjob/:id" element={<ViewJob />} />

              <Route path="/managecategory" element={<ManageCategory />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/addblog" element={<AddBlog />} />
              <Route path="/updateblog/:id" element={<UpdateBlog />} />
              <Route path="/viewblog/:id" element={<ViewBlog />} />

              <Route path="/casestudys" element={<CaseStudys />} />
              <Route path="/addcasestudy" element={<AddCaseStudy />} />
              <Route
                path="/updatecasestudy/:id"
                element={<UpdateCaseStudy />}
              />
              <Route path="/viewcasestudy/:id" element={<ViewCaseStudy />} />

              <Route path="/users" element={<Users />} />
              <Route path="/adduser" element={<AddUser />} />
              <Route path="/updateuser/:id" element={<UpdateUser />} />
              <Route path="/viewuser/:id" element={<ViewUser />} />
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Routes>
      </Suspense>
    </LoaderProvider>
  );
}

export default App;
